import PropTypes from 'prop-types';

// import { Link } from 'react-router-dom';

// material-ui
// import { ButtonBase } from '@mui/material';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// project import
// import LogoMain from '../../assets/images/icons/logo.svg';
// import LogoIcon from '../../assets/images/icons/logo-icon-io.svg';
// import { APP_DEFAULT_PATH } from 'config';
import  hilconaMob from '../../assets/images/mob-image/hilcona_mob_size.png';
import  repowerMob from '../../assets/images/mob-image/repower-mob_size.ico';

// ==============================|| MAIN LOGO ||============================== /


const LogoSection = ({ isIcon, sx, 
  // to 
}) =>{
  const theme = useTheme();

   const profileData = JSON.parse(localStorage.getItem('profiledata'))?.instance;
   const MobImg = profileData === 'hilcona' ? hilconaMob : repowerMob;

  const logoSrc = theme.palette.mode === 'dark' ? `${process.env.REACT_APP_IMAGE_URL}${localStorage.getItem('whiteLogoImage').replace(/"/g, '')}`
    :`${process.env.REACT_APP_IMAGE_URL}${localStorage.getItem('whiteLogoImage').replace(/"/g, '')}`;
      console.log("process.env.REACT_APP_IMAGE_URL",logoSrc);


    
return (
  <Box

  /* <ButtonBase disableRipple  */
  // component={Link} to={!to ? "/" : to} 
  sx={isIcon?{sx}:{padding:'15px 10px 0px 40px'}}>
    {isIcon ? <img height={28} src={MobImg} alt='Logo'  /> : 
    //<img src={'https://portal.netgate.io-procurement.com/ngtFiles/netgate05/ngtSysImages/instance/logo-hilcona.png'} alt='Logo' height={70} width={100}/>}
    <img src={logoSrc} alt="Logo" height={70} width={100} />}
  {/* </ButtonBase> */}
    </Box>
);
};
LogoSection.propTypes = {
  isIcon: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string
};

export default LogoSection;
