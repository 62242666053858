// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import { SearchProvider } from './hooks/useSearchContext';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';

import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@mui/material';

const App = () => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    // Check if service worker is supported
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'NEW_VERSION') {
          setShowUpdateModal(true);
        }
      });
    }
  }, []);

  const handleRefresh = () => {
    setShowUpdateModal(false);
    window.location.reload(true); // Force reload to get the new version
  };

  return (
    <ThemeCustomization>
      {/* <RTLLayout> */}
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <>
              <Notistack>
                <SearchProvider>
                  <Routes />
                </SearchProvider>
                <Snackbar />
              </Notistack>
            </>
          </AuthProvider>
        </ScrollTop>
      </Locales>
      {/* </RTLLayout> */}

      {/* Update Modal */}
      {showUpdateModal && (
        <Modal open={showUpdateModal} onClose={() => setShowUpdateModal(false)}>
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 24,
            outline: 'none',
            textAlign: 'center'
          }}>
            <h2>Update Available</h2>
            <p>A new version of the app is available. Please refresh to update.</p>
            <Button variant="contained" color="primary" onClick={handleRefresh}>
              Refresh
            </Button>
          </div>
        </Modal>
      )}
    </ThemeCustomization>
  );
};

export default App;
