import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import MainPage from 'pages/extra-pages/main-page';
import { Navigate } from 'react-router';
import useConfig from 'hooks/useConfig';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));
const Unauthorized = Loadable(lazy(() => import('pages/maintenance/unautorized')));

// render - meta search page
const MetaSearchPage = Loadable(lazy(() => import('pages/extra-pages/meta-search-page')));

// render - history page
const HistoryPage = Loadable(lazy(() => import('pages/extra-pages/history')));

// render - details page
const DetailsPage = Loadable(lazy(() => import('pages/extra-pages/details/details')));

//legal components
const LegalWrapper = ({ Component }) => {
  const { i18n: language } = useConfig();

  const LazyComponent = Loadable(lazy(() => import(`pages/extra-pages/legal/${language}/${Component}_${language}`)));

  return (
    <div style={{ height: 'auto', maxHeight: '83vh', overflowY: 'auto' }}>
      <LazyComponent />
    </div>
  );
};

const UserGuard = Loadable(lazy(() => import('utils/route-guard/UserGuard')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '',
      element: (
        <UserGuard>
        <MainLayout />
        </UserGuard>
      ),
      children: [
        {
          path: '/archive/category-search',
          element: <MainPage /> 
        },
        {
          path: '/archive/search',
          children: [
            {
              index: true,
              element: <MetaSearchPage /> 
            },
            {
              path: ':metasearchQuery', 
              element: <MetaSearchPage />
            }
          ]
        },
        {
          path: '/archive/:companyQuery/search',
          children: [
            {
              index: true,
              element: <MetaSearchPage />
            },
            {
              path: ':metasearchQuery',
              element: <MetaSearchPage />
            }
          ]
        },
        {
          path: '/archive/:companyQuery/quicksearch',
          children: [
            {
              index: true,
              element: <MetaSearchPage /> 
            },
            {
              path: ':metasearchQuery', 
              element: <MetaSearchPage />
            }
          ]
        },
        {
          path: '/:companyQuery/archive/search',
          children: [
            {
              index: true,
              element: <MetaSearchPage /> 
            },
            {
              path: ':metasearchQuery', 
              element: <MetaSearchPage />
            }
          ]
        },     
        {
          path: '/:companyQuery/autologin/archive/search',
          children: [
            {
              index: true,
              element: <MetaSearchPage /> 
            },
            {
              path: ':metasearchQuery', 
              element: <MetaSearchPage />
            }
          ]
        },       
        {
          path: '/:companyQuery/archive',
          children: [
            {
              index: true,
              element: <MetaSearchPage /> 
            },
            {
              path: ':metasearchQuery', 
              element: <MetaSearchPage />
            }
          ]
        },
        {
          path: '/:companyQuery/archive/quicksearch',
          children: [
            {
              index: true,
              element: <MetaSearchPage />
            },
            {
              path: ':metasearchQuery', 
              element: <MetaSearchPage />
            }
          ]
        },
        {
          path: '/archive/quicksearch',
          children: [
            {
              index: true,
              element: <MetaSearchPage /> 
            },
            {
              path: ':metasearchQuery', 
              element: <MetaSearchPage />
            }
          ]
        },
        {
          path: '/archive/history',
          element: <HistoryPage />
        },
        {
          path: '/details/:id', // Dynamic route for details page
          element: <DetailsPage />
        },
        {
          path: '/terms-condition',
          element: <LegalWrapper Component={"terms_and_conditions"} />
        },
        {
          path: '/privacy-policy',
          element: <LegalWrapper Component={"privacy_policy"} />
        },
        {
          path: '/legal-notices',
          element: <LegalWrapper Component={"legal_notices"} />
        },
        {
          path: '*',
          element: <Navigate to="/archive/search" replace />
        },
        {
          path: '*',
          element: <Navigate to="/archive/search" replace />
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        },
        {
          path: 'unauthorized',
          element: <Unauthorized />
        }
      ]
    },
    // Catch-all route for undefined paths
    {
      path: '*',
      element: <Unauthorized />
    }
  ]
};

export default MainRoutes;
